import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import PublicIcon from '@material-ui/icons/Public';
import { store } from '../store';
import {AssessmentTypes, setCurrentLanguage} from '../actions/assessment';
import { LanguageModel } from '../interface/LanguageModel';
import { useSelector } from 'react-redux';
import { AssessmentStateViewModel } from '../store/types';

// interface Language {
//   code: string;
//   label: string;
// }

// const languages: Language[] = [
//   { code: 'en', label: 'English' },
//   { code: 'es', label: 'Español' },
//   { code: 'fr', label: 'Français' },
//   // Add more languages here
// ];

const LanguageSelector: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const translation = useSelector((state: AssessmentStateViewModel) => state.translation) as LanguageModel;
//   const [selectedLanguage, setSelectedLanguage] = <LanguageModel>(store.getState().translation);
  //const [availableLanguages, setAvailableLanguages] = useState<Language>(languages[0]);

  console.log("In lang:")
  console.log(store.getState().availableLanguages)
  console.log(translation);

  const handleLanguage = (event: React.MouseEvent<HTMLElement>) => {
    let language = store.getState().availableLanguages?.filter(lang=>lang.language == event.currentTarget.title)[0] as any;
    // store.dispatch({ type: AssessmentTypes.SET_CURRENT_LANGUAGE, payload: language });
    console.log("In lang selection")
    console.log(language);
    store.dispatch(setCurrentLanguage(language as LanguageModel));
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

//   const handleLanguageSelect = (language: Language) => {
//     setSelectedLanguage(language);
//     handleClose();
//     // You can implement language change logic here
//   };

  return (
    <Box>
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<PublicIcon />}
      >
        {translation.displayName}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {store.getState().availableLanguages?.map((lang) => (
          <MenuItem
            title={lang.language}
            onClick={handleLanguage}
          >
            {lang.displayName}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageSelector;
