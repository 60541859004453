import React, { Component } from 'react';
import './App.css';
import VerticalLinearStepper from './components/VerticalLinearStepper';
import Keycloak from 'keycloak-js';
import { store } from './store';
import { addImpactFactor, setAvailableLanguages, updateType } from './actions/assessment';
import { ImpactFactorValue } from './interface/impactfactor';
import Config from './pweltconfig.json';
import { LanguageModel } from './interface/LanguageModel';
import swe_translation from "./translations/swedish.json";
import eng_translation from "./translations/english.json";

const useMockData = false;

interface PweltConfig{
    REACT_APP_API_URL: string;
}

//function App() {

  interface IProp {
    keycloak?: Keycloak.KeycloakInstance;
  }

interface IState {
  keycloak?: Keycloak.KeycloakInstance;
  loading: boolean;
  error: boolean;
  eventId: String;
  apiBaseUrl: string;
}

class App extends React.Component<IProp, IState> {

  constructor(props: IProp) {
    super(props);
    this.state = {
      keycloak: props.keycloak,
      loading: true,
      error: false,
      eventId: "",
      apiBaseUrl: (Config as PweltConfig).REACT_APP_API_URL,
    };
  }

  // Function for using mock data
  private mockData() {
    let temp:ImpactFactorValue = {key:"workload", value: 0, comment: "", commentVisible: true, impactFactorId: "id1", displayName: "Arbetsbelastning"};
    store.dispatch(addImpactFactor(temp));
    let other = {key:"other", value: 0, comment: "", commentVisible: true, impactFactorId: "id2", displayName: "Övrigt"} as ImpactFactorValue;
    store.dispatch(addImpactFactor(other));
    let configuration = {
      assessmentEventId: "test",
      configuration: {
        type: 0
      }
    };
    store.dispatch(updateType(configuration.configuration.type));
    let languages:LanguageModel[] = [];
    let swe = {"language": swe_translation.language, "displayName": "Svenska", "dictionary": swe_translation.dictionary} as LanguageModel | any; 
    languages.push(swe);
    let eng = {"language": eng_translation.language, "displayName": "English", "dictionary": eng_translation.dictionary} as LanguageModel | any; 
    languages.push(eng);
    store.dispatch(setAvailableLanguages(languages));
    this.setState({
      loading: false,
      eventId: configuration.assessmentEventId,
    });
  }

  private loadLanguages(availableEventId: string) {
    // Check for available languages
    var url = this.state.apiBaseUrl + 'languages/all'; 
    var req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.setRequestHeader('Accept', 'application/json');
    req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    if(this.state.keycloak != null) {
      req.setRequestHeader('Authorization', 'Bearer ' + this.state.keycloak.token);
    }  
    req.onreadystatechange  = () => {
      if (req.readyState === 4) {
          if (req.status === 200) {
            var languages = JSON.parse(req.response) as LanguageModel[];
            let tempLangs = [] as LanguageModel[];
            console.log(languages);
            let count = 0;
            languages.forEach(language=>{
                new Promise((resolve, reject) => {
                    let xhr = new XMLHttpRequest();
                    let langDataUrl = this.state.apiBaseUrl + 'languages/' + language.language; 
                    console.log("langDataUrl:");
                    console.log(langDataUrl);
                    xhr.open('GET', langDataUrl, true);
                    xhr.setRequestHeader('Accept', 'application/json');
                    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
                    if(this.state.keycloak != null) {
                      xhr.setRequestHeader('Authorization', 'Bearer ' + this.state.keycloak.token);
                    }                  
                    xhr.onload = () => {
                        if (xhr.status >= 200 && xhr.status < 300) {
                            resolve(xhr.response as LanguageModel);
                        } else {
                            reject(xhr.statusText);
                        }
                    };
                    xhr.onerror = () => reject(xhr.statusText);
                    xhr.send();
                }).then(lang => {
                  count += 1;
                  console.log("LANG:")
                  console.log(lang);
                  let obj = JSON.parse(lang as string);
                  // console.log(lang as LanguageModel | any);
                  let tempLang = {"language": language.language, "displayName": language.displayName, "dictionary": obj["dictionary"]} as LanguageModel | any;
                  console.log(tempLang);
                  //language.dictionary = (lang as LanguageModel).dictionary;
                  tempLangs.push(tempLang);
                  if(count==languages.length) {
                    console.log("All languages loaded");
                    console.log(tempLangs);
                    store.dispatch(setAvailableLanguages(tempLangs));
                    this.setState({
                      loading: false,
                      eventId: availableEventId,
                    });        
                  }
                }).catch(obj => {
                  console.log("Failed");
                  console.log(obj);
                })
            });
            // Need to be stopped until finished
            // console.log(languages);
            // store.dispatch(setAvailableLanguages(languages));
            
            // this.setState({
            //   loading: false,
            //   eventId: availableEventId,
            // });
          } else {
              console.log("Language loading failed!");
          }
        }
    }
    req.send();
  }

  public componentWillMount() {
    if(useMockData) {
      this.mockData();
    } else {
    // if(this.state.keycloak != null) {
    // Call api to check for available assessment event  
    // var url = 'https://pweltapi.pweltapp.se/api/v1/assessmentEvent/unit/available'; 
    var url = this.state.apiBaseUrl + 'assessmentEvent/unit/available'; 
    var req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.setRequestHeader('Accept', 'application/json');
    req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    if(this.state.keycloak != null) {
      req.setRequestHeader('Authorization', 'Bearer ' + this.state.keycloak.token);
    }  
    req.onreadystatechange  = () => {
      if (req.readyState === 4) {
          if (req.status === 200) {
            var configuration = JSON.parse(req.response);
            console.log(configuration)
            console.log(configuration.configuration)
            console.log(configuration.configuration.impactFactorConfigurations);
            console.log(configuration.configuration.impactFactorConfigurations[0]);
            // TODO Use configuration for order of items later
            let other = {} as ImpactFactorValue;
            let other_found = false;
            configuration.configuration.impactFactorConfigurations.forEach((element: any) => {
              //console.log(element.displayName);
              console.log(element.impactFactor.name);
              if(element.impactFactor.name == "other") {
                other = {key:element.impactFactor.name, value: 0, comment: "", commentVisible: element.commentVisible == 1, impactFactorId: element.impactFactor.impactFactorId, displayName: element.displayName} as ImpactFactorValue;
                other_found = true;
              } else {
                let temp:ImpactFactorValue = {key:element.impactFactor.name, value: 0, comment: "", commentVisible: element.commentVisible == 1, impactFactorId: element.impactFactor.impactFactorId, displayName: element.displayName};
                store.dispatch(addImpactFactor(temp));  
              }
            });
            if(other_found) {
              store.dispatch(addImpactFactor(other));
            }
            store.dispatch(updateType(configuration.configuration.type));
            var availableEventId = configuration.assessmentEventId;
            console.log("Current eventId" + availableEventId);
            this.loadLanguages(availableEventId);
            // this.setState({
            //   loading: false,
            //   eventId: availableEventId,
            // });
          } else {
            this.setState({
              error: true,
            });
          }
        }
    }
    req.send();
    // } else {
      // console.log(this.state.apiBaseUrl + 'assessmentEvent/unit/available');
      // this.setState({
      //   loading: false,
      //   eventId: 'test',
      // });
    // }
    }
  }

  public submitAssessment() {
    console.log("In submit of App");
    console.log(store.getState());
    var url = this.state.apiBaseUrl + 'assessment/createUnitAssessment'; 
  
    var req = new XMLHttpRequest();
    req.open('POST', url + "/" + this.state.eventId, true);
    req.setRequestHeader('Accept', 'application/json');
    req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    if(this.state.keycloak != null) {
      req.setRequestHeader('Authorization', 'Bearer ' + this.state.keycloak.token);
    }
    req.onreadystatechange = function () {
        if (req.readyState === 4) {
            if (req.status === 200) {
                console.log(req.responseText);
            } else {
                alert("Inskickningen misslyckades!");
            }
        } 
    }
    req.send(JSON.stringify(store.getState().assessment));
  }

  public render() {
    console.log("In render");
    return (
      <div className="App">
        {this.state.loading && !this.state.error && <div>Laddar...</div>}
        {!this.state.loading && !this.state.error && 
          <VerticalLinearStepper handleSubmit={this.submitAssessment.bind(this)} />
        }
        {this.state.error && <div>Du har ingen skattning att göra!</div>}
          {/* <VerticalLinearStepper handleSubmit={this.submitAssessment.bind(this)} /> */}
      </div>
    );  
  }
}

export default App;
