import React, {useState, useEffect} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MainAssessment from './MainAssessment';
import MinMaxAssessment from './MinMaxAssessment';
import Prognosis from './Prognosis';
import ImpactFactor from './ImpactFactor';
import Actions from './Actions';
import { store } from '../store';
import LanguageSelector from './LanguageSelector';
import { useSelector } from 'react-redux';
import { AssessmentStateViewModel } from '../store/types';
import { LanguageModel } from '../interface/LanguageModel';
import { get } from 'http';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '1300px',
      background: 'white',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }),
);

//Texts
function mainAssessmentTexts():string[] {return [
  store.getState().translation.dictionary["main1"],
  store.getState().translation.dictionary["main2"],
  store.getState().translation.dictionary["main3"],
  store.getState().translation.dictionary["main4"],
  store.getState().translation.dictionary["main5"],
  store.getState().translation.dictionary["main6"],
  store.getState().translation.dictionary["main7"],
  store.getState().translation.dictionary["main8"],
  store.getState().translation.dictionary["main9"],
  store.getState().translation.dictionary["main10"]
];}

function usersMainAssessmentTexts():string[] { return [
  store.getState().translation.dictionary["user_main1"],
  store.getState().translation.dictionary["user_main2"],
  store.getState().translation.dictionary["user_main3"],
  store.getState().translation.dictionary["user_main4"],
  store.getState().translation.dictionary["user_main5"],
  store.getState().translation.dictionary["user_main6"],
  store.getState().translation.dictionary["user_main7"],
  store.getState().translation.dictionary["user_main8"],
  store.getState().translation.dictionary["user_main9"],
  store.getState().translation.dictionary["user_main10"]
];}
// const usersMainAssessmentTexts:string[] = [
//   store.getState().translation.dictionary["user_main1"],
//   store.getState().translation.dictionary["user_main2"],
//   store.getState().translation.dictionary["user_main3"],
//   store.getState().translation.dictionary["user_main4"],
//   store.getState().translation.dictionary["user_main5"],
//   store.getState().translation.dictionary["user_main6"],
//   store.getState().translation.dictionary["user_main7"],
//   store.getState().translation.dictionary["user_main8"],
//   store.getState().translation.dictionary["user_main9"],
//   store.getState().translation.dictionary["user_main10"]
// ];

// const mainAssessmentTexts:string[] = [
//   'Negativa effekter av långvarig eller intensiv stress utan återhämtning.',
//   'Kraven överstiger kraftigt tillgängliga resurser.',
//   'Man går på knäna för att lösa uppgifterna, brist på kontroll över situationen.',
//   'Tydlig obalans, uppgifter får bortprioriteras och långvarig stress tar på bufferten. Startar på minus.',
//   'Viss obalans som måste hanteras med stöd från chef eller annan.',
//   'Hyfsad balans mellan krav och resurser. Viss ansträngning för att lösa uppgifterna, men det kan lösas med åtgärder som t.ex. planering.',
//   'God balans mellan krav och resurser, men det kan variera mellan olika uppgifter.',
//   'God och stabil balans mellan krav och resurser.',
//   'Bra balans mellan resurser och krav med lite överskott av resurser. En klar buffert om kraven ökar.',
//   'Gruppen har ett överskott av resurser, kan hjälpa andra och ta extra uppgifter.'
// ];

function minMaxAssessmentTexts():string[] { return [
  store.getState().translation.dictionary["min1"],
  store.getState().translation.dictionary["min2"],
  store.getState().translation.dictionary["min3"],
  store.getState().translation.dictionary["min4"],
  store.getState().translation.dictionary["min5"],
  store.getState().translation.dictionary["min6"],
  store.getState().translation.dictionary["min7"],
  store.getState().translation.dictionary["min8"],
  store.getState().translation.dictionary["min9"],
  store.getState().translation.dictionary["min10"]
];}

// const minMaxAssessmentTexts:string[] = [
//   'Utebliven funktion pga långvarig eller intensiv stress.',
//   'Individen löser inte sin uppgift trots vilja.',
//   'Individen går på knäna för att lösa uppgifterna, brist på kontroll över situationen.',
//   'Tydlig obalans, uppgifter får bortprioriteras och långvarig stress tar på bufferten.',
//   'Viss obalans som måste hanteras med stöd från chef eller annan.',
//   'Viss negativ påverkan på individnivå men i huvudsak fungerande med hjälp av aktivt ledarskap',
//   'Det råder i huvudsak balans mellan krav och resurser. Det kan förekomma toppar som individen dock kan hantera',
//   'Normalläge, allt fungerar som det ska.',
//   'Normalläge, allt fungerar som det ska, individen trivs och kan bidra till gruppen.',
//   'Individen har mer resurser än vad som krävs och kan hjälpa andra och ta sig an nya uppgifter.'
// ];

//const colors:

function getText(key:string):string {
  let language:any = store.getState().translation;
  let text:string = language.dictionary[key];
  return text;
}

function getSteps() {
  if(store.getState().assessment.type==0){ //Unit
    return ['weekly_assessment', 'min_max', 'prognosis', 'impact_factors', 'actions'];
    // return ['Veckoskatting', 'Min och Max', 'Prognos', 'Påverkansfaktorer', 'Åtgärder'];
  } else {
    return ['weekly_assessment', 'prognosis', 'impact_factors'];
    // return ['Veckoskatting', 'Prognos', 'Påverkansfaktorer'];
  }
}

function getStepContent(step: number) {
  if(store.getState().assessment.type==0){ //Unit
    switch (step) {
      case 0:
        return <MainAssessment 
                    getText={getText} 
                      // title={store.getState().translation.dictionary["main_title"]} 
                      // header={store.getState().translation.dictionary["main_header"]} 
                      texts={mainAssessmentTexts}
                      currentIndex={store.getState().assessment.assessmentMain}
                      />
  
      case 1:
        return <MinMaxAssessment 
                getText={getText}
                // title={store.getState().translation.dictionary["min_max_title"]}
                // header={store.getState().translation.dictionary["min_max_header"]}  
                texts={minMaxAssessmentTexts} value={[store.getState().assessment.assessmentMin, store.getState().assessment.assessmentMax]} /> 
                // texts={mainAssessmentTexts} value={[4, 7]} /> 
      case 2:
        return <Prognosis 
                //title={store.getState().translation.dictionary["prognosis_title"]} 
                // header={'Gör en bedömning av hur skattningen håller sig över den kommande veckan (kort prognos) och över en längre tid (lång prognos). Komplettera vid behov skattningarna med en kort beskrivning i fritextfältet.'} 
                //header={store.getState().translation.dictionary["prognosis_header"]} 
                getText={getText}
                prognosisShortterm={"" + store.getState().assessment.prognosisShortterm} prognosisLongtterm={"" + store.getState().assessment.prognosisLongterm} 
                prognosisShorttermComment={store.getState().assessment.prognosisShorttermComment} prognosisLongttermComment={store.getState().assessment.prognosisLongtermComment} />
      case 3:
        return <ImpactFactor 
                // title={store.getState().translation.dictionary["impact_factor_title"]} 
                // header={'Gör en bedömning av påverkansfaktorerna i nivåerna Ingen, Viss eller Stor. Ingen = Faktorn förekommer ej alternativt i sådan omfattning att den väl ryms inom ramen för befintliga resurser. Ingen negativ påverkan. Viss = faktorn finns och kan ha viss påverkan men är hanterbar med de resurser som finns. I kombination med andra faktorer medför den en negativ påverkan. Stor = Faktorn har en klar och tydlig negativ påverkan på gruppen.'}
                // header={store.getState().translation.dictionary["impact_factor_header"]}
                getText={getText}
                impactFactors={store.getState().assessment.impactfactors} />
      case 4:
        return <Actions 
                getText={getText}
                //title={store.getState().translation.dictionary["actions_title"]} 
                //header={store.getState().translation.dictionary["actions_header"]} 
                shorttermAction={store.getState().assessment.actionsShortterm} longtermAction={store.getState().assessment.actionsLongterm} />
      default:
        return 'Unknown step';
    }  
  } else { // User assessment 
    switch (step) {
      case 0:
        return <MainAssessment 
                    getText={getText}
                      // title={store.getState().translation.dictionary["user_main_title"]} 
                      // header={store.getState().translation.dictionary["user_main_header"]} 
                      texts={usersMainAssessmentTexts}
                      currentIndex={store.getState().assessment.assessmentMain}
                      />  
      case 1:
        return <Prognosis 
                //title={'Prognos'} 
                // header={'Gör en bedömning av hur skattningen håller sig över den kommande veckan (kort prognos) och över en längre tid (lång prognos). Komplettera vid behov skattningarna med en kort beskrivning i fritextfältet.'} 
                //header={store.getState().translation.dictionary["user_prognosis_header"]} 
                getText={getText}
                prognosisShortterm={"" + store.getState().assessment.prognosisShortterm} prognosisLongtterm={"" + store.getState().assessment.prognosisLongterm} 
                prognosisShorttermComment={store.getState().assessment.prognosisShorttermComment} prognosisLongttermComment={store.getState().assessment.prognosisLongtermComment} />
      case 2:
        return <ImpactFactor 
                // title={'Påverkansfaktorer'} 
                // header={'Gör en bedömning av påverkansfaktorerna i nivåerna Ingen, Viss eller Stor. Ingen = Faktorn förekommer ej alternativt i sådan omfattning att den väl ryms inom ramen för befintliga resurser. Ingen negativ påverkan. Viss = faktorn finns och kan ha viss påverkan men är hanterbar med de resurser som finns. I kombination med andra faktorer medför den en negativ påverkan. Stor = Faktorn har en klar och tydlig negativ påverkan på gruppen.'}
                // header={store.getState().translation.dictionary["user_impact_factor_header"]}
                getText={getText}
                impactFactors={store.getState().assessment.impactfactors} />
      default:
        return 'Unknown step';
    }  
  }
}

/*async function submitAssessmentA():Promise<void> {
  let url = 'https://testapi.pwelt.net/api/v1/assessment/createUserAssessment';
  let content = '';
  let result = store.getState();
  console.log(JSON.stringify(result));
  const response = await fetch(url, {
    method:'POST',
    body: content,
    headers: {'Content-Type': 'application/json; charset=UTF-8'} });
  
  if (!response.ok) { 
    console.log('Submit successful!');
  }
  
}*/

/*
function submitAssessment() {
  var url = 'https://testapi.pwelt.net/api/v1/organization/all';

  var req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.setRequestHeader('Accept', 'application/json');
  req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
  req.setRequestHeader('Authorization', 'Bearer ' + keycloak.token);

  req.onreadystatechange = function () {
      if (req.readyState === 4) {
          if (req.status === 200) {
              console.log(req.responseText);
              //console.log(req.data);
          } else {
              console.log("Failed");
          }
      }
  }
  req.send();
}*/

interface IProp {
  handleSubmit: Function;
}

interface IState {
  handleSubmit: Function;
}

export default function VerticalLinearStepper(props:IProp) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const translation = useSelector((state: AssessmentStateViewModel) => state.translation);

  console.log("translation in vls");
  console.log(translation);

  const handleNext = () => {
    if(activeStep == steps.length - 1) {
      props.handleSubmit();
      console.log('submitted');
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0,0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <LanguageSelector />
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{translation.dictionary[label]}</StepLabel>
            <StepContent>
              <div>{getStepContent(index)}</div>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    {store.getState().translation.dictionary['back']}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? store.getState().translation.dictionary['submit'] : store.getState().translation.dictionary['next']}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>{store.getState().translation.dictionary["temp_thanks_1"]}</Typography>
          <Typography>{store.getState().translation.dictionary["temp_thanks_2"]}</Typography>
          {/* <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button> */}
        </Paper>
      )}
    </div>
  );
}
