import {UPDATE_MAIN_ASSESSMENT, UPDATE_MINMAX_ASSESSMENT, UPDATE_PROGNOSIS_SHORTTERM, UPDATE_PROGNOSIS_LONGTERM, 
    UPDATE_PROGNOSIS_SHORTTERM_COMMENT, UPDATE_PROGNOSIS_LONGTERM_COMMENT, UPDATE_ACTION_SHORTTERM, UPDATE_ACTION_LONGTERM, UPDATE_IMPACT_FACTORS, ADD_IMPACT_FACTOR, UPDATE_TYPE, SET_AVAILABLE_LANGUAGES, SET_CURRENT_LANGUAGE} from '../constants/assessment';
import {AssessmentTypes} from '../actions/assessment';
//import { Reducer } from 'react';
import {AssessmentState, AssessmentStateViewModel} from '../store/types';
import {ImpactFactorValue} from '../interface/impactfactor';
import translation from "../translations/swedish.json";
import { assert } from 'console';
import { LanguageModel } from '../interface/LanguageModel';

const initialState: AssessmentStateViewModel = {
    assessment: {
    type: 0, 
    assessmentMain: 6,
    assessmentMin: 3,
    assessmentMax: 8,
    prognosisShortterm: 0,
    prognosisLongterm: 0,
    prognosisShorttermComment: '',
    prognosisLongtermComment: '',
    actionsShortterm: '',
    actionsLongterm: '',
    impactfactors: [],
    },
    keycloak: null,
    translation: {"language": translation.language, "displayName": "Svenska", "dictionary": translation.dictionary} as LanguageModel | any, 
    availableLanguages: null,
    // impactfactors: [{key:"anxiety", value: 0, comment: ""}, {key:"workload", value: 0, comment: ""}, {key:"moral", value: 0, comment: ""}, {key:"protection-equippment", value: 0, comment: ""}, {key:"other", value: 0, comment: ""}],
}

function rootReducer(state = initialState, action: AssessmentTypes) {    
    if (action.type === UPDATE_MAIN_ASSESSMENT) {
      let tempAssessment = state.assessment;
      tempAssessment.assessmentMain = action.payload as number;
      return Object.assign({}, state, {
        assessment: tempAssessment
      });
    } else if (action.type === UPDATE_MINMAX_ASSESSMENT) {
      let temp = [3, 8];
      if(action.payload) {
        temp = action.payload;
      }
      let tempAssessment = state.assessment;
      tempAssessment.assessmentMin = temp[0];
      tempAssessment.assessmentMax = temp[1];
      return Object.assign({}, state, {
        assessment: tempAssessment
        // assessmentMin: temp[0],
        // assessmentMax: temp[1],    
      });
    } else if (action.type === UPDATE_PROGNOSIS_SHORTTERM) {
      let tempAssessment = state.assessment;
      tempAssessment.prognosisShortterm = action.payload as number;
        return Object.assign({}, state, {
          assessment: tempAssessment
          // prognosisShortterm: action.payload
        });
    } else if (action.type === UPDATE_PROGNOSIS_SHORTTERM_COMMENT) {
      let tempAssessment = state.assessment;
      tempAssessment.prognosisShorttermComment = action.payload as string;
        return Object.assign({}, state, {
          assessment: tempAssessment
          // prognosisShorttermComment: action.payload
        });
    } else if (action.type === UPDATE_IMPACT_FACTORS) {
      let tempAssessment = state.assessment;
      let temp = state.assessment.impactfactors;  
      //state.impactfactors.forEach((value: number, key: string) =>{
      //state.impactfactors.forEach((key: string, value: number) =>{
      for(let i=0; i < state.assessment.impactfactors.length; i++){
        let key = state.assessment.impactfactors[i].key;
        if(action.payload?.key == key) {
          temp[i] = {key: key, value:action.payload.value as number, comment: action.payload.comment, commentVisible: action.payload.commentVisible, impactFactorId: action.payload.impactFactorId, displayName: action.payload.displayName}
          // temp[i] = {key: key, value:action.payload.get(key) as number}
          //temp.set(key, action.payload.get(key) as number);
        }
      };
      tempAssessment.impactfactors = temp;
      return Object.assign({}, state, {
        assessment: tempAssessment
        //impactfactors: temp
      });
    } else if (action.type === UPDATE_PROGNOSIS_LONGTERM) {
      let tempAssessment = state.assessment;
      tempAssessment.prognosisLongterm = action.payload as number;
        return Object.assign({}, state, {
          assessment: tempAssessment
          // prognosisLongterm: action.payload
        });
    } else if (action.type === UPDATE_PROGNOSIS_LONGTERM_COMMENT) {
      let tempAssessment = state.assessment;
      tempAssessment.prognosisLongtermComment = action.payload as string;
      return Object.assign({}, state, {
        assessment: tempAssessment
          // prognosisLongtermComment: action.payload
        });
    } else if (action.type === UPDATE_ACTION_SHORTTERM) {
        let tempAssessment = state.assessment;
        tempAssessment.actionsShortterm = action.payload as string;
        return Object.assign({}, state, {
          assessment: tempAssessment
          // actionsShortterm: action.payload
        });
    } else if (action.type === UPDATE_ACTION_LONGTERM) {
        let tempAssessment = state.assessment;
        tempAssessment.actionsLongterm = action.payload as string;
        return Object.assign({}, state, {
          assessment: tempAssessment
          // actionsLongterm: action.payload
        });
    } else if (action.type === ADD_IMPACT_FACTOR) {
      let temp = state.assessment.impactfactors;
      temp.push(action.payload as ImpactFactorValue);
      let tempAssessment = state.assessment;
      tempAssessment.impactfactors = temp;
      return Object.assign({}, state, {
        assessment: tempAssessment
        // impactfactors: temp
      });
    } else if (action.type === UPDATE_TYPE) {
      let tempAssessment = state.assessment;
      tempAssessment.type = action.payload as number;
      return Object.assign({}, state, {
        assessment: tempAssessment
        // type: action.payload
      });
    } else if (action.type === SET_AVAILABLE_LANGUAGES) {
      return Object.assign({}, state, {
        availableLanguages: action.payload
      });
    } else if (action.type === SET_CURRENT_LANGUAGE) {
      return Object.assign({}, state, {
        translation: action.payload
      });
    }
    return state;
  }

export default rootReducer;
