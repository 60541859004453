import React, { ComponentProps } from 'react';
import { store } from '../store';
import { LanguageModel } from '../interface/LanguageModel';
import { connect } from 'react-redux';
import { AssessmentStateViewModel } from '../store/types';
import { AssessmentTypes } from '../actions/assessment';
import { Store } from 'redux';

interface IProp {
    texts: string[];
    currentIndex: number;
}  

interface IAdditionalProps {
    localTranslation: LanguageModel;
}  

interface IState {
    texts: string[];
    currentIndex: number;
    //localTranslation: LanguageModel;
}  

//type ICombinedProps = IProp & IAdditionalProps;

class AssessmentInfoComponent extends React.Component<IProp, IState> {

    constructor(props:IProp) {
        super(props);
        this.state = {
            texts: props.texts,
            currentIndex: props.currentIndex,
            // localTranslation: props.localTranslation
        }
    }

    componentDidUpdate() {
        // console.log("I updated");
    }

    render() {
        // const { localTranslation } = this.state;
        return (
            <div>
                <span>{this.props.texts[this.props.currentIndex]}</span>                    
            </div>
        ); 
    }
}

export default AssessmentInfoComponent;