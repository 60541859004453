import {UPDATE_MAIN_ASSESSMENT, UPDATE_MINMAX_ASSESSMENT, UPDATE_PROGNOSIS_SHORTTERM, UPDATE_PROGNOSIS_SHORTTERM_COMMENT, 
    UPDATE_PROGNOSIS_LONGTERM, UPDATE_PROGNOSIS_LONGTERM_COMMENT, UPDATE_IMPACT_FACTORS, UPDATE_ACTION_SHORTTERM, 
    UPDATE_ACTION_LONGTERM,
    ADD_IMPACT_FACTOR,
    UPDATE_TYPE, SET_CURRENT_LANGUAGE, SET_AVAILABLE_LANGUAGES} from '../constants/assessment';
import { LanguageModel } from '../interface/LanguageModel';
import { ImpactFactorValue } from '../interface/impactfactor';

interface SetAvailableLanguages {
    type: typeof SET_AVAILABLE_LANGUAGES;
    payload?: LanguageModel[] | null;
}

interface SetCurrentLanguage {
    type: typeof SET_CURRENT_LANGUAGE;
    payload?: LanguageModel;
}

interface UpdateMainAssessment {
    type: typeof UPDATE_MAIN_ASSESSMENT;
    payload?: number;
}

interface UpdateMinMaxAssessment {
    type: typeof UPDATE_MINMAX_ASSESSMENT;
    payload?: number[];
}

interface UpdatePrognosisShortterm {
    type: typeof UPDATE_PROGNOSIS_SHORTTERM;
    payload?: number;
}

interface UpdatePrognosisShorttermComment {
    type: typeof UPDATE_PROGNOSIS_SHORTTERM_COMMENT;
    payload?: string;
}

interface UpdatePrognosisLongterm {
    type: typeof UPDATE_PROGNOSIS_LONGTERM;
    payload?: number;
}

interface UpdatePrognosisLongtermComment {
    type: typeof UPDATE_PROGNOSIS_LONGTERM_COMMENT;
    payload?: string;
}

interface UpdateImpactFactor {
    type: typeof UPDATE_IMPACT_FACTORS;
    payload?: ImpactFactorValue;
}

interface UpdateActionShortterm {
    type: typeof UPDATE_ACTION_SHORTTERM;
    payload?: string;
}

interface UpdateActionLongterm {
    type: typeof UPDATE_ACTION_LONGTERM;
    payload?: string
}

interface AddImpactFactor {
    type: typeof ADD_IMPACT_FACTOR;
    payload?: ImpactFactorValue;
}

interface UpdateType {
    type: typeof UPDATE_TYPE;
    payload?: number;
}

export function setAvailableLanguages(languages: LanguageModel[]): SetAvailableLanguages {
    return {
        type: SET_AVAILABLE_LANGUAGES,
        payload: languages,
    }
}

export function setCurrentLanguage(language: LanguageModel): SetCurrentLanguage {
    return {
        type: SET_CURRENT_LANGUAGE,
        payload: language,
    }
}

export function updateMainAssessment(assessmentMain?: number): UpdateMainAssessment {
    return {
        type: UPDATE_MAIN_ASSESSMENT,
        payload: assessmentMain,
    };
}

export function updateMinMaxAssessment(assessmentMax?: number[]): UpdateMinMaxAssessment {
    return {
        type: UPDATE_MINMAX_ASSESSMENT,
        payload: assessmentMax,
    };
}

export function updatePrognosisShortterm(prognosisShortterm?: number): UpdatePrognosisShortterm {
    return {
        type: UPDATE_PROGNOSIS_SHORTTERM,
        payload: prognosisShortterm,
    };
}

export function updatePrognosisShorttermComment(prognosisShorttermComment?: string): UpdatePrognosisShorttermComment {
    return {
        type: UPDATE_PROGNOSIS_SHORTTERM_COMMENT,
        payload: prognosisShorttermComment,
    };
}

export function updatePrognosisLongterm(prognosisLongterm?: number): UpdatePrognosisLongterm {
    return {
        type: UPDATE_PROGNOSIS_LONGTERM,
        payload: prognosisLongterm,
    };
}

export function updatePrognosisLongtermComment(prognosisLongtermComment?: string): UpdatePrognosisLongtermComment {
    return {
        type: UPDATE_PROGNOSIS_LONGTERM_COMMENT,
        payload: prognosisLongtermComment,
    };
}

export function updateImpactFactor(impactFactor?: ImpactFactorValue): UpdateImpactFactor {
    return {
        type: UPDATE_IMPACT_FACTORS,
        payload: impactFactor,
    };
}

export function updateActionShortterm(actionShortterm?: string): UpdateActionShortterm {
    return {
        type: UPDATE_ACTION_SHORTTERM,
        payload: actionShortterm,
    };
}

export function updateActionLongterm(actionLongterm?: string): UpdateActionLongterm {
    return {
        type: UPDATE_ACTION_LONGTERM,
        payload: actionLongterm,
    };
}

export function addImpactFactor(impactFactor?: ImpactFactorValue): AddImpactFactor {
    return {
        type: ADD_IMPACT_FACTOR,
        payload: impactFactor,
    };
}

export function updateType(type: number): UpdateType {
    return {
        type: UPDATE_TYPE,
        payload: type,
    };
}

export type AssessmentTypes = UpdateMainAssessment | UpdateMinMaxAssessment | UpdatePrognosisShortterm | UpdatePrognosisShorttermComment
                            | UpdatePrognosisLongterm | UpdatePrognosisLongtermComment | UpdateActionShortterm | UpdateActionLongterm 
                            | UpdateImpactFactor | AddImpactFactor | UpdateType | SetCurrentLanguage | SetAvailableLanguages;